import React, { useState, useEffect } from "react"
import SEO from "../components/SEO"
import ButtonCustom from "../components/ButtonCustom"
import Icon from "../components/Icon"
import styles from "../styles/modules/static-pages.module.scss"
import { Input, Form } from "antd"
import { createUserManagementSession } from "../api"
import { Magic } from "magic-sdk"
import { loginUser } from "../utils/auth"

const ManageSubscription = () => {
  const [isLoading, setLoading] = useState(false)

  let magic
  useEffect(() => {
    if (window !== undefined) {
      magic = new Magic(process.env.GATSBY_MAGIC_API_PUBKEY)
    }
  })

  const onFinish = values => {
    if (magic !== undefined) {
      setLoading(true)
      loginUser(magic, values.email).then(token =>
        createUserManagementSession(token, values.email).then(
          url => (window.location = url)
        )
      )
    }
  }

  return (
    <div className="wrapper wrapper--straight">
      <SEO title="Manage Subscription" />
      <Icon icon="search" aesthetic="simple" />
      <h2 className="x-large-body-copy">Manage your recurring donation</h2>
      <div className={styles.subscriptionLoginAction}>
        <Form onFinish={onFinish}>
          <Form.Item name="email">
            <Input
              className={styles.inputItem}
              placeholder={"The email address you donated with"}
            />
          </Form.Item>
          <Form.Item>
            <ButtonCustom
              className={styles.checkoutButton}
              iconClassName={styles.checkoutButtonIcon}
              icon="heart"
              iconPulse
              size="medium"
              loading={isLoading}
            >
              Log in
            </ButtonCustom>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default ManageSubscription
